<script>
import APIUser from '@app/services/API/User';
import BuySeatForm from '@app/components/BuySeatForm.vue';
import UserEdit from '@app/components/UserEdit.vue';
import UserSwitch from '@app/components/UserSwitch.vue';
import UserInviterEdit from '@app/components/UserInviterEdit.vue';

export default {
  components: {
    BuySeatForm,
    UserEdit,
    UserSwitch,
    UserInviterEdit,
  },
  i18n: {
    messages: {
      fr: {
        user: {
          status: {
            ACTIVE: 'actif',
            SUSPENDED: 'suspendu',
          },
        },
        role: {
          ADMIN: 'Peut tout faire sauf supprimer l\'espace de formation.',
          TEACHER: 'Peut créer et modifier les formations auxquelles il/elle est assigné(e).',
          ANALYST: 'Peut tout consulter, mais ne peut rien créer, modifier ou supprimer.',
          OWNER: 'Propriétaire de l\'espace de formation.',
        },
      },
    },
  },
  static: {
    statusClass: {
      ACTIVE: 'is-primary',
      SUSPENDED: 'is-warning',
    },
  },
  data: () => ({
    users: [],
    invites: [],
    editedInvite: null,
    editedUser: null,
    switchedUser: null,
    reactivatedUser: null,
    isFetchingUsers: false,
    hasInviteModalOpen: false,
  }),
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    getUsers() {
      return APIUser.getUsers()
        .then(({ data }) => (this.users = data));
    },
    getInvites() {
      return APIUser.getInvites()
        .then(({ data }) => (this.invites = data));
    },
    loadAllUsers() {
      this.isFetchingUsers = true;
      Promise.all([this.getUsers(), this.getInvites()])
        .finally(() => (this.isFetchingUsers = false));
    },
    onInvited() {
      this.hasInviteModalOpen = false;
      this.reactivatedUser = null;
      const loader = this.$buefy.loading.open();
      setTimeout(
        () => {
          Promise.all([this.getInvites(), this.getUsers(), this.$store.dispatch('auth/fetch')])
            .finally(() => loader.close());
        },
        3000,
      );
    },
    onInviteUpdated(invite) {
      this.editedInvite = null;
      this.invites = this.invites.map((v) => {
        if (v.id === invite.id) {
          return invite;
        }

        return v;
      });
    },
    onUserUpdated(editedKey, user) {
      this[editedKey] = null;
      this.users = this.users.map((v) => {
        if (v.uuid === user.uuid) {
          return user;
        }

        return v;
      });

      if (editedKey === 'switchedUser') {
        const loader = this.$buefy.loading.open();
        this.getInvites()
          .finally(() => loader.close());
      }
    },
    reactivateUser(user) {
      this.hasInviteModalOpen = true;
      this.reactivatedUser = user;
    },
    resendInvite(invite) {
      const loader = this.$buefy.loading.open();
      APIUser.resendInvite(invite.id)
        .then(() => this.$showMessage.sent())
        .finally(() => loader.close());
    },
    deleteInvite(invite) {
      const confirmAction = () => {
        const loader = this.$buefy.loading.open();
        APIUser.deleteInvite(invite.id)
          .then(() => this.$store.dispatch('auth/fetch'))
          .then(() => {
            this.$showMessage.success();
            this.invites = this.invites.filter((v) => v.id !== invite.id);
          })
          .finally(() => loader.close());
      };

      if (this.authStore.capabilities.paid_user_accounts > 0) {
        this.$buefy.dialog.confirm({
          message: `
            <p>
              En supprimant cette invitation,
              l’abonnement associé à ce membre d'équipe
              prendra fin et vous ne serez plus facturé.
            </p>
            <p class="mt-3 has-text-weight-bold">
              Si vous souhaitez remplacer l’adresse <strong>${invite.email}</strong>
              par celle d’une autre personne,
              il est préférable de modifier l’invitation plutôt que de la supprimer.
            </p>
          `,
          focusOn: 'cancel',
          confirmText: 'Confirmer',
          onConfirm: confirmAction,
        });
      } else {
        this.$buefy.dialog.confirm({
          message: `
            <p>
                Voulez-vous vraiment supprimer cette invitation de
                <strong>${invite.email}</strong> ?
            </p>
            <p class="mt-3 has-text-weight-bold">
              Si vous voulez inviter quelqu'un d'autre à la place
              de <strong>${invite.email}</strong>,
              modifiez l'invitation à la place de la supprimer.
            </p>
          `,
          focusOn: 'cancel',
          confirmText: 'Confirmer',
          onConfirm: confirmAction,
        });
      }
    },

    deleteUser(user) {
      const confirmAction = () => {
        this.$buefy.dialog.prompt({
          message: `
          <p>
            Entrez votre mot de passe pour confirmer cette action.
          </p>
        `,
          focusOn: 'cancel',
          inputAttrs: { required: true, type: 'password' },
          onConfirm: (password) => {
            if (password) {
              const loader = this.$buefy.loading.open();
              APIUser.deleteUser(user.uuid, { password })
                .then(() => this.$store.dispatch('auth/fetch'))
                .then(() => {
                  this.$showMessage.success();
                  this.users = this.users.filter((v) => v.uuid !== user.uuid);
                })
                .finally(() => loader.close());
            }
          },
        });
      };

      if (this.authStore.capabilities.paid_user_accounts > 0) {
        this.$buefy.dialog.confirm({
          message: `
            <p>
              En supprimant ce membre, l'abonnement pour celui-ci est résilié
              et vous ne serez plus débité pour ce membre
            </p>
            <p class="mt-3 has-text-weight-bold">
              Si vous voulez inviter quelqu'un d'autre à la place de
              <strong>${user.firstname} ${user.lastname}</strong>,
              sélectionnez "remplacer" plutôt que "supprimer".
            </p>
          `,
          focusOn: 'cancel',
          confirmText: 'Confirmer',
          onConfirm: confirmAction,
        });
      } else {
        this.$buefy.dialog.confirm({
          message: `
            <p>
              Voulez-vous vraiment supprimer l'utilisater
              <strong>${user.firstname} ${user.lastname}</strong> ?
            </p>
            <p class="mt-3 has-text-weight-bold">
              Si vous voulez inviter quelqu'un d'autre à la place de
              <strong>${user.firstname} ${user.lastname}</strong>,
              sélectionnez "remplacer" plutôt que "supprimer".
            </p>
          `,
          focusOn: 'cancel',
          confirmText: 'Confirmer',
          onConfirm: confirmAction,
        });
      }
    },
  },
  created() {
    this.loadAllUsers();
  },
};
</script>

<template>
  <div>
    <h1 class="title">
      Gérez votre équipe
    </h1>

    <div class="content">
      <p>
        Besoin de travailler à plusieurs sur votre espace Teachizy ?
        Vous pouvez <strong>ajouter</strong> / <strong>retirer</strong> des membres à votre équipe
        et gérer leurs rôles. Chaque membre de votre équipe est facturé
        <strong>{{ $constants.PRICES.MONTHLY.USER_ACCOUNT }} € TTC par mois</strong>
        ou <strong>{{ $constants.PRICES.YEARLY.USER_ACCOUNT }} € TTC par an</strong>.
      </p>
    </div>

    <div v-if="$store.getters['auth/hasExpertPlan']" class="box is-info content">
      <h2 class="is-size-5">
        Vos avantages
      </h2>
      <p>
        Grâce à votre forfait EXPERT, vous bénéficiez de <strong>3 comptes membres inclus !</strong>
      </p>
    </div>
    <div v-else
      class="
        box is-info p-0 content is-flex is-justify-content-space-between
        is-flex-wrap-wrap is-align-items-center
      "
    >
      <div class="p-5">
        <h2 class="is-size-5">
          Le saviez-vous ?
        </h2>
        <p>
          En souscrivant au forfait EXPERT, vous bénéficiez de <strong>3 comptes membres inclus !</strong>
        </p>
        <p class="has-text-weight-bold">
          Vous êtes actuellement sur le
          <span v-t="`packs.${authStore.plan}`" />
        </p>
      </div>
      <div class="p-5">
        <b-button
          class="has-text-weight-bold"
          tag="router-link"
          type="is-warning"
          :to="{name: 'plan', query: {onglet: 'vous'}}"
        >
          Passer EXPERT
          <b-icon class="has-text-black ml-1" icon="gem" />
        </b-button>
      </div>
    </div>

    <b-modal
      :width="640"
      :active.sync="hasInviteModalOpen"
      :can-cancel="false"
      @close="reactivatedUser = null">
      <BuySeatForm
        v-if="hasInviteModalOpen"
        :user="reactivatedUser"
        @cancel="hasInviteModalOpen = false; reactivatedUser = null;"
        @done="onInvited"
      />
    </b-modal>

    <section>
      <nav class="is-flex is-justify-content-space-between">
        <h2 class="title is-4">
          Invitations en attente

          <template v-if="!isFetchingUsers">
            ({{ invites.length }})
          </template>
        </h2>
        <b-button type="is-primary" icon-left="user-plus" @click="hasInviteModalOpen = true">
          Inviter un membre
        </b-button>
      </nav>

      <b-skeleton v-if="isFetchingUsers" height="200" active />
      <div v-else ref="box" class="box is-relative mxw-full">
        <b-table :data="invites">
          <b-table-column label="Nom" field="firstname" v-slot="{ row }">
            <p>
              <strong>
                {{ row.firstname }} {{ row.lastname }}
              </strong>
            </p>
            <p class="mt-2">
              {{ row.email }}
            </p>
          </b-table-column>
          <b-table-column label="Rôle" field="role" cell-class="valign-middle" v-slot="{ row }">
            <b-tooltip multilined>
              <span v-t="`roles.${row.role}`" />
              <template #content>
                <span v-t="`role.${row.role}`" />
              </template>
            </b-tooltip>
          </b-table-column>
          <b-table-column label="Invité" field="created_at" cell-class="valign-middle" v-slot="{ row }">
            <p class="mt-1">
              <b-tooltip :label="$moment.utc(row.created_at).local().format('DD/MM/YY [à] HH:mm')">
                {{ row.created_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </p>
          </b-table-column>
          <b-table-column cell-class="valign-middle" v-slot="{ row }">
            <b-dropdown position="is-top-left">
              <template #trigger>
                <b-button type="is-text" icon-left="ellipsis-h" />
              </template>
              <b-dropdown-item @click="resendInvite(row)">
                Renvoyer le mail d'invitation
              </b-dropdown-item>
              <b-dropdown-item @click="editedInvite = row">
                Modifier
              </b-dropdown-item>
              <b-dropdown-item class="has-text-danger" @click="deleteInvite(row)">
                Supprimer
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template #empty>
            <p class="has-text-centered">
              Il n'y a aucune invitation en attente.
            </p>
          </template>
        </b-table>
      </div>
    </section>

    <section class="mt-5">
      <h2 class="title is-4">
        Membres

        <template v-if="!isFetchingUsers">
          ({{ users.length }})
        </template>
      </h2>

      <b-skeleton v-if="isFetchingUsers" height="200" active />
      <div v-else ref="box" class="box is-relative mxw-full">
        <b-table :data="users">
          <b-table-column label="Nom" field="firstname" v-slot="{ row }">
            <p>
              <strong>
                {{ row.firstname }} {{ row.lastname }}
              </strong>
            </p>
            <p class="mt-2">
              {{ row.email }}
            </p>
          </b-table-column>
          <b-table-column label="Rôle" field="role" cell-class="valign-middle" v-slot="{ row }">
            <b-tooltip multilined>
              <span v-t="`roles.${row.role}`" />
              <template #content>
                <span v-t="`role.${row.role}`" />
              </template>
            </b-tooltip>
          </b-table-column>
          <b-table-column label="Statut" field="status" cell-class="valign-middle" v-slot="{ row }">
            <span
              :class="`tag is-medium ${$options.static.statusClass[row.status]}`"
              v-t="`user.status.${row.status}`"
            />
          </b-table-column>
          <b-table-column label="Connecté" field="last_login_at" cell-class="valign-middle" v-slot="{ row }">
            <p v-if="row.last_login_at" class="mt-1">
              <b-tooltip :label="$moment.utc(row.last_login_at).local().format('DD/MM/YY [à] HH:mm')">
                {{ row.last_login_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </p>
            <p v-else class="mt-1 has-text-weight-bold">
              Jamais connecté
            </p>
          </b-table-column>
          <b-table-column
            v-if="$store.getters['auth/permissions'].canManageUsers"
            cell-class="valign-middle"
            v-slot="{ row }">
            <b-dropdown v-if="row.role != 'OWNER' && row.uuid != authUser.uuid" position="is-top-left">
              <template #trigger>
                <b-button type="is-text" icon-left="ellipsis-h" />
              </template>
              <b-dropdown-item v-if="row.status == 'ACTIVE'" @click="editedUser = row">
                Modifier le rôle
              </b-dropdown-item>
              <b-dropdown-item v-if="row.status == 'ACTIVE'" @click="switchedUser = row">
                Remplacer
              </b-dropdown-item>
              <b-dropdown-item v-if="row.status == 'SUSPENDED'" @click="reactivateUser(row)">
                Réactiver
              </b-dropdown-item>
              <b-dropdown-item
                class="has-text-danger"
                @click="deleteUser(row)">
                Supprimer
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
          <template #empty>
            <p class="has-text-centered">
              Il n'y a aucune invitation en attente.
            </p>
          </template>
        </b-table>
      </div>
    </section>

    <b-modal v-if="editedInvite" :width="480" active @close="editedInvite = null">
      <UserInviterEdit :userInvite="editedInvite" @done="onInviteUpdated" />
    </b-modal>

    <b-modal v-if="editedUser" :width="480" active @close="editedUser = null">
      <UserEdit :user="editedUser" @done="onUserUpdated('editedUser', $event)" />
    </b-modal>

    <b-modal v-if="switchedUser" :width="480" active @close="switchedUser = null">
      <UserSwitch :user="switchedUser" @done="onUserUpdated('switchedUser', $event)" />
    </b-modal>
  </div>
</template>
